import { useEffect, useState } from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Center,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightAddon,
    PinInput,
    PinInputField,
    Textarea,
    useToast,
} from '@chakra-ui/react';

import { FaQrcode } from 'react-icons/fa';
import { isArdorAccount } from '../../../utils/validators';
import { sendDirectMessage } from '../../../services/Ardor/ardorInterface';
import { errorToast, okToast } from '../../../utils/alerts';
import { checkPin } from '../../../utils/walletUtils';

/**
 * @name NewMessage
 * @description Modal to send a new message
 * @param {ref} reference - reference to the button that opens the modal
 * @param {boolean} isOpen - if the modal is open or not
 * @param {function} onClose - function to close the modal
 * @param {string} username - username of the logged user
 */
const NewMessage = ({ reference, isOpen, onClose, username, defaultRecipient = '' }) => {
    const [ardorAccount, setArdorAccount] = useState(defaultRecipient);
    const [message, setMessage] = useState('');
    const [isValidArdorAccount, setIsValidArdorAccount] = useState(false);
    const [isValidPin, setIsValidPin] = useState(false); // invalid pin flag
    const [passphrase, setPassphrase] = useState('');
    const toast = useToast();

    const handleInputArdorAccount = e => {
        e.preventDefault();
        setArdorAccount(e.target.value);
    };

    useEffect(() => {
        const checkValidity = () => {
            const isValid = isArdorAccount(ardorAccount);
            setIsValidArdorAccount(isValid);
        };
        ardorAccount && checkValidity();
    }, [ardorAccount]);

    const handleInputMessage = e => {
        e.preventDefault();
        setMessage(e.target.value);
    };

    const handleCompletePin = pin => {
        isValidPin && setIsValidPin(false); // reset invalid pin flag

        const account = checkPin(username, pin);
        if (account) {
            setIsValidPin(true);
            setPassphrase(account.passphrase);
        }
    };

    const cleanAndClose = () => {
        setArdorAccount('');
        setMessage('');
        setPassphrase('');
        setIsValidPin(false);
        onClose();
    };

    const handleOk = async () => {
        try {
            const response = await sendDirectMessage({
                recipient: ardorAccount,
                passPhrase: passphrase,
                message,
            });

            if (response) okToast('Message sent', toast);
            else errorToast('Error sending message', toast);
            cleanAndClose();
        } catch (error) {
            console.error('🚀 ~ file: NewMessage.js:85 ~ handleOk ~ error:', error);
            errorToast('Error sending message', toast);
        }
    };

    const bgColor = '#d86471';
    const borderColor = '#f39d54';
    const filledColor = '#f79c27';

    return (
        <>
            <AlertDialog
                variant="generic"
                motionPreset="slideInBottom"
                leastDestructiveRef={reference}
                onClose={onClose}
                isOpen={isOpen}
                isCentered>
                <AlertDialogOverlay />

                <AlertDialogContent
                    bgColor={bgColor}
                    border="1px"
                    borderColor={borderColor}
                    shadow="dark-lg"
                    color="white">
                    <AlertDialogHeader>Send message</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <FormControl variant="floatingModalTransparent" id="Recipient" my={4} mt={8}>
                            <InputGroup size="lg" border="1px" borderColor={borderColor} rounded="lg">
                                <Input
                                    placeholder=" "
                                    value={ardorAccount}
                                    onChange={handleInputArdorAccount}
                                    border="0px"
                                    isInvalid={!isValidArdorAccount}
                                />
                                <InputRightAddon
                                    bgColor="transparent"
                                    border="0px"
                                    children={
                                        <IconButton
                                            bgColor="transparent"
                                            aria-label="Scan QR CODE"
                                            icon={<FaQrcode />}
                                        />
                                    }
                                />
                            </InputGroup>

                            <FormLabel>Recipient</FormLabel>
                        </FormControl>

                        <FormControl variant="floatingModalTransparent" id="Message" my={4} mt={8}>
                            <Textarea placeholder=" " value={message} onChange={handleInputMessage} />
                            <FormLabel>Message</FormLabel>
                        </FormControl>

                        <Center>
                            <HStack spacing={7}>
                                <PinInput
                                    size="lg"
                                    onComplete={handleCompletePin}
                                    onChange={handleCompletePin}
                                    isInvalid={!isValidPin}
                                    variant="filled"
                                    mask>
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                        </Center>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button mx={2} ref={reference} onClick={onClose} bgColor={filledColor} fontWeight={'black'}>
                            CANCEL
                        </Button>
                        <Button
                            ref={reference}
                            onClick={handleOk}
                            fontWeight={'black'}
                            isDisabled={!isValidArdorAccount || !isValidPin}
                            bgColor={filledColor}>
                            SEND NOW
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default NewMessage;
