import { Table, TableContainer, Tbody, Text, Tfoot, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { NQTDIVIDER } from '../../../../../data/CONSTANTS';
import { getAsset } from '../../../../../utils/cardsUtils';
import AskOrBidItem from './AskOrBidItem';

const AskBidTable = ({
    type,
    onlyOneAsset,
    orders,
    cards,
    onOpen,
    setSelectedOrder,
    canDelete,
    isLeft = false,
    newStyle = false,
    textColor = 'black',
}) => {
    const bgHeadColor = useColorModeValue('blackAlpha.300', 'whiteAlpha.300');

    const borderLeft = isLeft ? 'lg' : 'none';
    const borderRight = !isLeft ? 'lg' : 'none';
    const isAsk = type === 'Asks';

    // const MAX_ORDERS = 6;

    // if(orders.length > MAX_ORDERS) {
    //     orders = orders.slice(0, MAX_ORDERS);
    // }

    return (
        <TableContainer
            rounded="lg"
            bg="blackAlpha"
            maxH={newStyle ? '20rem' : '60rem'}
            overflowY={'auto'}
            borderColor={bgHeadColor}
            borderLeftRadius={!newStyle ? borderLeft : 'none'}
            borderRightRadius={!newStyle ? borderRight : 'none'}>
            {(isAsk || newStyle === false) && (
                <Text
                    textAlign="center"
                    p={4}
                    fontSize="lg"
                    borderBottom="2px"
                    fontWeight={'black'}
                    borderColor={!newStyle ? '#3b6497' : '#f79c27'}
                    color={newStyle ? (isAsk ? 'white' : '#29a992') : "white"}>
                    {type}
                </Text>
            )}

            <Table variant="simple">
                {!newStyle && (
                    <Thead>
                        <Tr borderBottom={'2px solid #3b6497'}>
                            {!onlyOneAsset && (
                                <Th textAlign="center" color={'#3b6497'}>
                                    Asset
                                </Th>
                            )}
                            <Th textAlign="center" color={'white'}>
                                Ignis
                            </Th>
                            <Th textAlign="center" color={'white'}>
                                Amount
                            </Th>
                        </Tr>
                    </Thead>
                )}
                <Tbody>
                    {orders.map(order => {
                        const _asset = onlyOneAsset ? order.asset : getAsset(order.asset, cards);
                        if (!_asset) return null;
                        return (
                            <AskOrBidItem
                                key={order.orderFullHash}
                                order={order.order}
                                asset={_asset}
                                ignis={order.priceNQTPerShare / NQTDIVIDER}
                                amount={order.quantityQNT}
                                isAsk={isAsk}
                                onOpen={onOpen}
                                setSelectedOrder={setSelectedOrder}
                                onlyOneAsset={onlyOneAsset}
                                canDelete={canDelete}
                            />
                        );
                    })}
                </Tbody>
                {newStyle && isAsk && (
                    <Tfoot bgColor="whiteAlpha.400">
                        <Tr color="white">
                            {!onlyOneAsset && <Th textAlign="center">Asset</Th>}
                            <Th textAlign="center" color="white" fontWeight={'black'}>
                                Ignis
                            </Th>
                            <Th textAlign="center" color="white" fontWeight={'black'}>
                                Amount
                            </Th>
                        </Tr>
                    </Tfoot>
                )}
            </Table>

            {!isAsk && newStyle && (
                <Text
                    textAlign="center"
                    p={4}
                    fontSize="lg"
                    borderTop="2px"
                    borderColor={'#f79c27'}
                    color={isAsk ? '#eb6473' : 'white'}
                    fontWeight={'black'}>
                    {type}
                </Text>
            )}
        </TableContainer>
    );
};

export default AskBidTable;
