import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// ----------------------------------------------------------------
// -----------------  Configuración de Chakra UI  -----------------
// ----------------------------------------------------------------

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const styles = {
    global: props => ({
        body: {
            color: mode('gray.800', 'whiteAlpha.900')(props),
            bg: mode('white', 'black')(props),
        },
        '#root': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
    }),
};

const components = {
    // Change Menu default color in dark mode to black
    Menu: {
        baseStyle: props => ({
            list: {
                bg: mode('white', 'black')(props),
            },
            item: {
                bg: mode('white', 'black')(props),
                _focus: {
                    bg: mode('gray.100', 'whiteAlpha.200')(props),
                },
            },
        }),
    },
    Table: {
        baseStyle: props => ({
            td: {
                borderBotton: 'none',
            },
        }),
    },
    Switch: {
        variants: {
            mbswitch: props => ({
                track: {
                    bg: mode('blackAlpha.300', 'whiteAlpha.300')(props),
                    _checked: {
                        bg: '#70348c',
                    },
                },
                thumb: {
                    bg: 'white',
                    _checked: {
                        bg: 'white',
                    },
                },
            }),
        },
    },
    Button: {
        variants: {
            bridge: props => ({
                border: '2px solid #393b97',
                color: mode('rgba(57,59,151,1)', 'white')(props),
                fontWeight: 'black',
            }),
        },
    },
    Drawer: {
        baseStyle: props => ({
            dialog: {
                bg: mode('white', 'dark')(props),
            },
        }),
    },
    Form: {
        variants: {
            floating: props => ({
                container: {
                    label: {
                        bg: props.colorMode === 'dark' ? 'black' : 'white',
                        top: -5,
                        left: 0,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: 'sm',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        mx: 3,
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
            floatingModal: props => ({
                container: {
                    label: {
                        bg: '#122f34',
                        color: 'white',
                        top: -6,
                        left: 0,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: 'sm',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
            floatingModalTransparent: props => ({
                container: {
                    label: {
                        bg: 'transparent',
                        color: 'white',
                        top: -6,
                        left: 0,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: 'sm',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
            whatsAppStyleLeft: props => ({
                container: {
                    label: {
                        bg: 'transparent',
                        color: props.colorMode === 'dark' ? 'white' : 'black',
                        bottom: -4,
                        left: 1,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: '2xs',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
            whatsAppStyleRight: props => ({
                container: {
                    label: {
                        bg: 'transparent',
                        color: props.colorMode === 'dark' ? 'white' : 'black',
                        bottom: -4,
                        right: 1,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: '2xs',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
            floatingTransparent: props => ({
                container: {
                    input: {
                        borderColor: '#393b97',
                    },
                    label: {
                        bg: 'transparent',
                        color: props.colorMode === 'dark' ? 'white' : 'black',
                        top: -6,
                        left: 0,
                        zIndex: 2,
                        position: 'absolute',
                        fontSize: 'sm',
                        fontWeight: 'bold',
                        pointerEvents: 'none',
                        px: 1,
                        my: 2,
                        transformOrigin: 'left top',
                    },
                },
            }),
        },
    },
};

export const theme = extendTheme({ config, components, styles });
